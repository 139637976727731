<template>
  <el-dialog
    title="Загрузка НСИ"
    :visible.sync="dialogVisible"
    width="40%"
    class="upload_nsi"
    :close-on-click-modal="false"
  >
    <el-form v-if="dialogVisible" id="uploadForm"
          enctype="multipart/form-data" >
      <div class="mb-1">
        <span class="mr-2">{{ fileText }}</span>
        <br />
        <div id="file-chosen">
          <label id="file-label" for="file">Обзор</label>
          <div id="file-name"
            :class="{emptyFileName : !fileName}">{{fileName? fileName : 'Выберите файл'}}</div>
          <el-button size="small" type="success" @click="submitUpload">Загрузить файл</el-button>
        </div>

        <input @change="handleFileChange" id="file" type="file" name="file" hidden/>
      </div>
      <el-progress :percentage="percentage"></el-progress>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">Отменить</el-button>
      <el-button :disabled="!uploadComplete || !fileId"
                 size="small" type="success"
                 @click="parseFromFile">
          Загрузить данные из файла</el-button>
    </span>
  </el-dialog>
</template>

<script>
import uploadNsiApi from '@/api/administration/uploadNsi';

export default {
  name: 'UploadNsiModal',
  props: {
    value: { type: Boolean, default: () => false },
    fileText: { type: String, default: () => 'Выберите файл' },
    remoteParseMethod: { type: Function, default: () => null }
  },
  data() {
    return {
      // fileList: [],
      fileName: null,
      // filePath: null,
      fileId: null,
      uploadInProgress: false,
      uploadComplete: false,
      percentage: 0
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    handleFileChange(event) {
      this.percentage = 0;
      this.uploadComplete = false;

      this.fileName = event.target.files[0].name;
    },
    async submitUpload() {
      if (!this.fileName) {
        this.$message({
          type: 'warning',
          message: 'Необходимо выбрать файл',
        });

        return;
      }

      this.percentage = 0;
      this.uploadComplete = false;

      const form = document.getElementById('uploadForm');
      const formData = new FormData(form);

      const config = {
        onUploadProgress: (progressEvent) => {
          this.percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      };

      this.uploadInProgress = true;
      const res = await uploadNsiApi.uploadFile(formData, config);
      if (res && res.data) {
        this.fileId = res.data;
        this.uploadInProgress = false;
        this.uploadComplete = true;
      } else {
        this.percentage = 0;
        this.uploadComplete = false;
      }
    },
    async parseFromFile() {
      const result = await this.remoteParseMethod(this.fileId);
      this.$message({
        type: result.data.succeeded ? 'success' : 'warning',
        message: result.data.resultMessage
      });

      if (result.data.succeeded) this.$emit('start-upload');
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
 #file-chosen{
   width:100%;
   button{
      margin-left: 1rem;
   }
 }
</style>
