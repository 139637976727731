import axios from 'axios';

export default {
  uploadFile(formData, config) {
    return axios.post('/api/UploadNsi/UploadFile',
      formData, config);
  },
  getUploads(importTypeId, pageNumber, pageSize, sortField, sortDesc) {
    return axios.get('/api/UploadNsi/GetUploads', {
      params: {
        importTypeId, pageNumber, pageSize, sortField, sortDesc
      }
    });
  },
  parseAip(fileId) {
    return axios.post('/api/UploadNsi/ParseAip', { fileId });
  },
  parseAnalysisMapping(fileId) {
    return axios.post('/api/UploadNsi/ParseAnalysisMapping', { fileId });
  },
  parsePkAnalysis(fileId) {
    return axios.post('/api/UploadNsi/ParsePkAnalysis', { fileId });
  },
};

