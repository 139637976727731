<template>
  <div>
    <el-row class="m-0" :gutter="20">
      <el-col :span="6">
        <el-button @click="handleUploadDialogOpen" type="text" icon="el-icon-circle-plus-outline">Загрузить</el-button>
      </el-col>
      <el-col :span="12">
        <pagination
          class="text-center"
          :total-count="totalCount"
          :page-size.sync="pageSize"
          :current-page.sync="pageNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <div>
      <el-table style="width: 100%;"
                v-loading="loading"
                :data="aipUploads"
                :height="tableHeight"
                :default-sort = "{prop: 'startTime', order: 'descending'}"
                @sort-change="handleSortChange">
        <el-table-column
              prop="startTime"
              label="Дата начала"
              sortable="custom"
              min-width="15"
              :formatter="dateTimeRenderer">
        </el-table-column>
        <el-table-column
              prop="endTime"
              label="Дата окончания"
              sortable="custom"
              min-width="15"
              :formatter="dateTimeRenderer">
        </el-table-column>
        <el-table-column prop="isSuccessful"
                         label="Завершено успешно"
                         sortable="custom"
                         min-width="10"
                         :formatter="boolRenderer">
        </el-table-column>
        <el-table-column prop="initiatorLogin"
                         label="Логин"
                         sortable="custom"
                         min-width="15">
        </el-table-column>
        <el-table-column prop="fileName"
                         label="Имя файла"
                         sortable="custom"
                         min-width="20">
        </el-table-column>
        <el-table-column prop="resultDetails"
                         label="Доп. информация"
                         sortable="custom"
                         min-width="30">
        </el-table-column>
      </el-table>
    </div>
    <upload-nsi-modal v-if="dialogVisible"
                      v-model="dialogVisible"
                      :file-text="fileText"
                      :remote-parse-method="remoteParseMethod"
                      @start-upload="getUploads" />
  </div>
</template>

<script>
import uploadNsiApi from '@/api/administration/uploadNsi';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import tableResize from '@/mixins/tableResize.js';
import UploadNsiModal from './UploadNsiModal';

export default {
  name: 'UploadNsiList',
  props: {
    importTypeId: { type: Number, default: () => 0 },
    fileText: { type: String, default: () => 'Выберите файл' },
    remoteParseMethod: { type: Function, default: () => null }
  },
  mixins: [formatters, tableResize],
  components: { UploadNsiModal, pagination },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      aipUploads: [],
      pageNumber: 1,
      pageSize: 50,
      totalCount: 0,
      sortField: 'startTime',
      sortDesc: true,
    };
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    });
    await this.getUploads();
    this.tableHeight = this.getTableHeight();
  },
  methods: {
    async getUploads() {
      this.loading = true;
      const res = await uploadNsiApi.getUploads(
        this.importTypeId,
        this.pageNumber,
        this.pageSize,
        this.sortField,
        this.sortDesc,
      );
      if (res.data) {
        this.aipUploads = res.data.items;
        this.totalCount = res.data.totalCount;
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getUploads();
    },
    async handleCurrentChange() {
      await this.getUploads();
    },
    async handleSortChange(event) {
      this.sortField = event.prop;
      this.sortDesc = event.order === 'descending';
      await this.getUploads();
    },
    handleUploadDialogOpen() {
      this.dialogVisible = true;
    },
  }
};
</script>

<style scoped>

</style>
